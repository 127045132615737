<template>
    <div>
        <div v-for="(item, index) in groupData" class="node-hover" :key="item.name">
            <div :style="{'margin-left': 25 * depth + 'px'}">
                <div class="d-flex justify-content-between">
                    <div class="node" @click="getGroupData(item.id)">
                        <span>{{ expanded && item.id == groupId ? '&#9660' : '&#9658'}}</span>
                        <span>{{ item.name }}</span>
                    </div>
                    <div class="d-flex">
                        <div  class="dropdown w-30">
                            <button type="button" class="w-44 btn btn-sm dropdown-toggle py-0" data-bs-toggle="dropdown">
                                <img src="/app-assets/images/icons/plus-solid.svg" alt="">
                            </button>
                            <div class="dropdown-menu dropdown-menu-end">
                                <a @click.prevent="onClickAddGroupBtn(item)" v-if="depth < 3" class="dropdown-item" href="#">
                                    <span>Add sub Group</span>
                                </a>
                                <a @click.prevent="onClickAddProductBtn(item)" class="dropdown-item" href="#">
                                    <span>Add Product</span>
                                </a>
                            </div>
                        </div>
                        <div class="w-20 z-999"></div>
                        <div>
                            <img
                                @click="emitGroupEdit(item)"
                                width="13" 
                                class="cursor-pointer" 
                                src="/app-assets/images/icons/pencil-alt-solid.svg" 
                                alt=""
                            >
                        </div>
                    </div>
                </div>

                <div>
                    <TreeBrowser
                        :key="item.id"
                        v-if="item.id == groupId && expanded"
                        :groupData="nestedData.group"
                        :endpointData="nestedData.endpoint"
                        :id="item.id"
                        :depth="depth + 1"
                        @paginationEmit="onPageChange"
                        :loader="loading"
                    />
                </div>
            </div>
        </div>

        <Loader v-if="loader"/>

        <div v-if="endpointData.data && endpointData.data.length > 0" class="pt-2">
            <div v-for="(item, index) in endpointData.data" :key="item.name" :style="{'margin-left': 25 * depth + 'px'}">
                <div class="d-flex cursor-initial justify-content-between bg-black-light rounded-8 p-1 mb-1">
                    <p class="mb-0">{{ item.name }}</p>
                    <div class="d-flex">
                        <img @click="emitProductEdit(item.id)" width="13" class="cursor-pointer" src="/app-assets/images/icons/pencil-alt-solid.svg" alt="">
                    </div>
                </div>
            </div>
            
            <div v-if="totalPage(endpointData) > 1" class="d-flex justify-content-end">
                <Paginate
                    :page-count="totalPage(endpointData)"
                    :force-page="endpointData.current_page"
                    :click-handler="pagination"
                >
                </Paginate>
            </div>
        </div>

    </div>
</template>

<script setup>
    import { ref, inject, reactive, defineEmits } from 'vue';
    import handleInventory from '@/services/modules/inventory';
    import { useRoute, useRouter } from 'vue-router';
    import TreeBrowser from '@/components/molecule/company/v2/inventory/product-service/ProductTree';
    import Paginate from "vuejs-paginate-next";
    import Loader from '@/components/atom/LoaderComponent'

    const { fetchGroupByParent } = handleInventory();
    const emitter = inject('emitter');

    const emit = defineEmits(['paginationEmit'])
    
    const $route = useRoute();
    const router = useRouter();
    const loading = ref(false);
    const nestedData = reactive({
        group: [],
        endpoint: {}
    })
    const groupId = ref(null);
    const expanded = ref(false);

    const props = defineProps({
        groupData: Array,
        endpointData: Object,
        depth: {
            type: Number,
            default: 0
        },
        id: Number,
        pageCount: Number,
        loader: {
            type: Boolean,
            default: false
        }
    })

    function getQuery(page) {
        let query = `?company_id=${$route.params.companyId}`;
        if (page) query += `&page=${page}`;
        return query
    }

    const emitProductEdit = (id) => {
        emitter.emit('onClickProduct', id)
    }

    const emitGroupEdit = (data) => {
        emitter.emit('onGroupUpdate', {
            eventName: 'onUpdateGroup',
            title: 'Edit group',
            data: data
        })
    }

    const totalPage = (endpointData) => {
        if(endpointData.total <= endpointData.per_page) return 1
        let num = parseInt(endpointData.total / endpointData.per_page)
        let fraction = endpointData.total % endpointData.per_page
        if(fraction !== 0) return num + 1
        return num
    }

    const onClickAddGroupBtn = (data) => {
        emitter.emit('onClickAddGroup', data)
    }

    const onClickAddProductBtn = (data) => {
        emitter.emit('onClickAddProduct', data)
    }

    const fetchData = (id, page = 1) => {
        loading.value = true
        let query = getQuery(page);
        fetchGroupByParent(id, query).then((res) => {
            if(page < 2) {
                nestedData.group = res.data.group
            }
            nestedData.endpoint = res.data.endpoint
        }).catch(e => console.log('Something went wrong'))
        .finally(() => {
            loading.value = false
        })
    }

    const getGroupData = async(id) => {
        if(expanded.value && id == groupId.value) {
            expanded.value = !expanded.value
            return
        }
        
        groupId.value = id;
        expanded.value = true;
        fetchData(groupId.value)
    }

    const pagination = async(page) => {
        emit('paginationEmit', page)
    }

    const onPageChange = async (page) => {
        fetchData(groupId.value, page)
    }
</script>

<style scoped>
.margin{
    margin-left: 25px;
}
.sub-group {
    margin-left: 25px;
}
.bg-black-light {
    background: #00000024;
}
.rounded-8 {
    border-radius: 8px;
}
.node {
    cursor: pointer;
}
.node-hover {
    padding: 10px 2px;
}
.node-hover:hover {
    background: #fff;
}
.cursor-initial {
    cursor: initial;
}
.cursor-pointer {
    cursor: pointer;
}
.w-30 {
    width: 30px;
}
.w-20 {
    width: 20px;
}
.z-999 {
    z-index: 999;
}
.w-44 {
    width: 44px;
}
</style>