<template>
    <div class="card p-2" style="min-height: calc(100vh - 185px);">
        <div class="d-flex flex-wrap justify-content-between align-item-center">
            <h3>Product & Service List</h3>
            <div class="gx-1">
                <!-- <button
                    style="min-width: 64px;"
                    @click="openSpecificationAddModal"
                    class="btn btn-secondary waves-effect waves-float waves-light me-1"
                >Add Specification
                </button> -->
                <button
                    style="min-width: 64px;"
                    @click="goToList"
                    class="btn btn-secondary waves-effect waves-float waves-light me-1"
                >List
                </button>
                <button
                    style="min-width: 64px;"
                    @click="goToImport"
                    class="btn btn-primary waves-effect waves-float waves-light me-1"
                >Import
                </button>
                <button
                    style="min-width: 64px;"
                    @click="openGroupAddModal"
                    class="btn btn-secondary waves-effect waves-float waves-light me-1"
                >New Group
                </button>
                <button
                    style="min-width: 64px;"
                    @click="openProductAddModal"
                    class="btn btn-primary waves-effect waves-float waves-light"
                >Add Product
                </button>
            </div>
        </div>
        <div class="mt-2"></div>
        <Tab 
            @onClickTab="onClickTab"
            :tabs="homeContent.product_type"
            routeName="inventory-list"
        />
        <hr>
        <TreeBrowser
            v-if="renderTree"
            :style="{opacity: chartLoading ? .5 : 1}"
            :groupData="productSet.group"
            :endpointData="productSet.endpoint"
        />
        <p v-if="!productSet.group.length" class="text-center mt-5">No data</p>

        <SpecificationAddModal
            :clue="clue"
            :formData="specification"
            ref="specification"
            @onSubmitSpecification="onSubmitSpecification"
        />
        <ProductAddModal
            :accountHeads="accountHeads"
            :selectedSpecifications="selectedSpecifications"
            :specifications="specifications"
            :clue="clue"
            :units="units"
            :group="productGroup"
            :formData="product"
            :inventoryMethods="inventoryMethods"
            :setBrandValue="setBrandValue"
            :removeBrandValue="removeBrandValue"
            @onClickSubmitProduct="handleProductSubmit"
            @onCloseModal="onCloseModal"
            ref="product"
        />
        <ProductEditModal
            :accountHeads="accountHeads"
            :selectedSpecifications="selectedSpecifications"
            :specifications="specifications"
            :clue="clue"
            :units="units"
            :group="productGroup"
            :formData="productSingle"
            :inventoryMethods="inventoryMethods"
            :productSingleOriginal="productSingleOriginal"
            :setBrandValue="setBrandValuetoEditform"
            :removeBrandValue="removeBrandValue"
            @onCloseModal="onCloseModal"
            @onClickUpdateProduct="handleProductUpdate"
            ref="productedit"
        />
        <GroupAddModal
            modalTitle="Add Group"
            ref="group"
            :type="homeContent.product_type"
            :formData="groupAddData"
            :status="homeContent.status"
            :group="productGroup"
            @onSubmitGroup="handleGroupSubmit"
        />
        <GroupAddModal
            submitEvent="onUpdateGroup"
            :showParent="true"
            modalTitle="Edit Group"
            ref="groupedit"
            :type="homeContent.product_type"
            :formData="groupEditData"
            :status="homeContent.status"
            :group="productGroup"
            @onUpdateGroup="handleGroupUpdate"
        />

        <Loader v-if="loading"/>
    </div>
</template>

<script>
import handleInventory from '@/services/modules/inventory'
import { inject } from 'vue'
import {mapMutations} from 'vuex'

import TreeBrowser from '@/components/molecule/company/v2/inventory/product-service/ProductTree'
import Tab from '@/components/atom/Tab'
import TitleButton from '@/components/atom/TitleButton'
import Loader from '@/components/atom/LoaderComponent'
import SpecificationAddModal from '@/components/molecule/company/inventory/product-service/SpecificationAddModal'
import ProductAddModal from '@/components/molecule/company/inventory/product-service/ProductAddModal'
import ProductEditModal from '@/components/molecule/company/inventory/product-service/ProductEditModal'
import GroupAddModal from '@/components/molecule/company/inventory/product-service/GroupAddModal'

export default {
    name: 'InventoryList',

    components: {
        TitleButton,
        TreeBrowser,
        Tab,
        SpecificationAddModal,
        ProductAddModal,
        ProductEditModal,
        Loader,
        GroupAddModal
    },

    data: () => ({
        loading: false,
        clue: [],
        units: [],
        productGroup: [],
        productList: [],
        productSingle: {},
        productSingleOriginal: {},
        specifications: {},
        selectedSpecifications: [],
        accountHeads: [],
        inventoryMethods: [],
        isGroup: false,
        parentGroup: '',
        productSet: {
            group: [],
            endpoint: {}
        },
        homeContent: {},
        groups: [],
        createData: {
            name: '',
            account_type: '',
            status: 'active',
            account_head_id: null,
            company_id: ''
        },
        editData: {
            name: '',
            account_type: '',
            status: 'active',
            account_head_id: null,
            company_id: '',
            _method: 'PATCH',
        },
        specification: {
            company_id: '',
            clue: null,
            name: '',
            description: ''
        },
        product: {
            company_id: '',
            product_head_id: null,
            contact_profile_id: null,
            name: '',
            specifications: {},
            unit_id: null,
            purchase_price: '',
            purchase_account_head_id: null,
            sales_price: '',
            sales_account_head_id: null,
            cost_of_sales: '',
            cost_of_sales_account_head_id: null,
            inventory_account_head_id: null,
            default_purchase_unit_id: null,
            default_sales_unit_id: null,
            default_shipping_unit_id: null,
            alternative_unit_ids: [],
            sku: '',
            short_description: '',
            full_description: '',
            image: null,
            status: 'active',
            additional_images: [],
            vat_rate: '',
            inventory_method: 'fifo',
        },
        groupAddData: {
            name: '',
            product_type: null,
            status:'active',
            company_id: '',
            head_group_id: null
        },
        groupEditData: {},
        renderTree: true
    }),

    computed: {
        companyId() {
            return this.$route.params.companyId
        },
        productType() {
            return this.$route.params.accountType
        }
    },

    methods: {
        ...mapMutations({
            setChartAccount: 'chartAccount/setChartAccount'
        }),

        goToImport() {
            this.$router.push({name: 'import-product-list', params: this.$route.params, query: this.$route.query})
        },

        onCloseModal() {
            this.selectedSpecifications=[]
            this.product.specifications = {}
            this.resetForm()
        },

        sortChart(data) {
            // (data)
            data.map(i => {
                i.child.sort((a, b) => {
                    if(a.type == 'group' && b.type == 'endpoint') return 1
                    if(a.type == 'endpoint' && b.type == 'group') return -1
                    if(a.type == b.type) return 0
                })
                this.sortChart(i.child)
            })
        },

        openGroupAddModal () {
            this.groupAddData.product_type = this.productType
            this.$refs.group.toggleModal()
        },

        openProductAddModal () {
            this.$refs.product.toggleModal()
        },

        onClickTab() {
            this.getProductList(this.accountType)
            this.getGroup()
        },

        setGroupArr(data) {
            data.map(item => {
                if(item.type == 'group') {
                    this.groups.push({id: item.id, name: item.name})
                }
                this.setGroupArr(item.child)
            })
        },

        goToList() {
            this.$router.push({name: 'product-service-list', params: this.$route.params, query: this.$route.query})
        },

        async onUpdateGroup() {
            // return;
            try {
                this.chartCreateLoading = true
                let res = await this.updateAccountGroup({
                    data: this.editData,
                    id: this.currentEditItemId
                })
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    this.$refs.editChartAccount.toggleModal();
                    this.getChartAccount(this.$route.params.accountType)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.chartCreateLoading = false
            }
        },

        async getHomeContent(type) {
            try {
                this.chartLoading = true
                let res = await this.fetchHome()
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.homeContent = res.data
                    await this.prepareInventoryMethods();
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.chartLoading = false
            }
        },

        async prepareInventoryMethods(){
          this.inventoryMethods = [];
          Object.keys(this.homeContent.inventory_methods).forEach((val)=>{
            this.inventoryMethods.push({
              id : val,
              name : this.homeContent.inventory_methods[val],
            })
          });
        },

        async getClue() {
            try {
                this.loading = true
                let res = await this.fetchClue(this.companyId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.clue = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        async getProductList() {
            const query = `?company_id=${this.companyId}&product_type=${this.$route.params.accountType}`
            try {
                this.loading = true
                let res = await this.fetchGroupParent(query)
                if(!res.status) {
                    this.productSet.group = []
                }
                if(res.status) {
                    this.productSet.group = res.data
                    this.productSet.endpoint = {}
                    this.renderTree = true
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        async getAccountHeads() {
            try {
                this.loading = true
                let res = await this.fetchAccountHeads(this.companyId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.accountHeads = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        async getUnit() {
            try {
                this.loading = true
                let res = await this.fetchUnit(this.companyId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.units = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        async getGroup() {
            try {
                this.loading = true
                let res = await this.fetchGroup(this.companyId, this.productType)
                if(!res.status) {
                    this.productGroup = []
                }
                if(res.status) {
                    this.productGroup = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        prepareProductEditData(data) {
            let editData = {
                id: data.id,
                company_id: this.companyId,
                product_head_id: data.product_id,
                name: data.name,
                specifications: {},
                unit_id: data.description.unit.id,
                purchase_price: data.description.purchase_price,
                purchase_account_head_id: data.description.purchase_account_head.id,
                sales_price:  data.description.sales_price,
                sales_account_head_id: data.description.sales_account_head.id,
                cost_of_sales: data.description.cost_of_sales,
                cost_of_sales_account_head_id: data.description.cost_of_sales_account_head.id,
                inventory_account_head_id: data.description.inventory_account_head.id,
                default_purchase_unit_id: data.description.default_purchase_unit_id,
                default_sales_unit_id: data.description.default_sales_unit_id,
                default_shipping_unit_id: data.description.default_shipping_unit_id,
                sku: data.sku,
                short_description: data.short_description,
                full_description: data.full_description,
                image: null,
                status: 'active',
                additional_images: [],
                vat_rate: data.description.vat_rate,
                alternative_units: data.alternative_units,
                inventory_method: data.description.inventory_method,
                supplier: data.supplier,
                contact_profile_id: data.contact_profile_id,
                recorder_level: data.recorder_level,
                _method: 'PATCH'
            }
            this.productSingle = editData
        },

        async fetchSingleProduct(id) {
            try {
                this.loading = true
                let res = await this.getProduct({id: id, company_id: this.companyId})
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.productSingleOriginal = res.data
                    this.prepareProductEditData(res.data)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        async onSubmitSpecification() {
            try {
                this.$refs.specification.loading = true
                this.specification.company_id = this.companyId
                let res = await this.storeSpecification(this.specification)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.$refs.specification.toggleModal()
                    this.specification = {
                        company_id: '',
                        clue: null,
                        name: '',
                        destination: ''
                    }
                    this.showSuccess(res.message)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                 this.$refs.specification.loading = false
            }
        },

        async handleProductUpdate() {
            this.renderTree = false
            try {
                if(this.productSingle.image == null) delete this.productSingle.image
                if(this.productSingle.inventory_method == null) delete this.productSingle.inventory_method
                if(this.productSingle.additional_images.length === 0) delete this.productSingle.additional_images
                this.$refs.productedit.loading = true
                // have to refactor
                let fd = new FormData();
                Object.keys(this.productSingle).forEach(key => {
                    if(
                        ! ["additional_images", "specifications", "alternative_unit_ids"].includes(key) &&
                        this.productSingle[key] !== null
                    ) {
                        fd.append(key, this.productSingle[key])
                    }
                })
                if(this.productSingle.additional_images) {
                    for(let i=0; i<this.productSingle.additional_images.length; i++) {
                        let key = `additional_images[${i}]`;
                        let value = this.productSingle.additional_images[i];
                        fd.append(key, value);
                    }
                }

                const alternativeUnitIds = this.productSingle.alternative_unit_ids.map(unitId => {
                    return {
                        unit_id: unitId
                    }
                })

                fd.append('specifications', JSON.stringify(this.productSingle.specifications))
                fd.append('alternative_unit_ids', JSON.stringify(alternativeUnitIds))
                // return
                let res = await this.updateProduct(fd, this.productSingle.id)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.$refs.productedit.toggleModal()
                    this.getProductList()
                    this.showSuccess(res.message)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                 this.$refs.productedit.loading = false
            }
        },

        async handleProductSubmit() {
            this.renderTree = false;
            try {
                this.$refs.product.loading = true
                this.product.company_id = this.companyId
                if(this.product.inventory_method == null) delete this.product.inventory_method
                // have to refactor
                let fd = new FormData();
                Object.keys(this.product).forEach(key => {
                    if(
                        ! ["additional_images", "specifications", "image", "alternative_unit_ids"].includes(key) &&
                        this.product[key] !== null
                    ) {
                        fd.append(key, this.product[key])
                    }
                })
                if(this.product.image) fd.append('image', this.product.image)
                for(let i=0; i<this.product.additional_images.length; i++) {
					let key = `additional_images[${i}]`;
					let value = this.product.additional_images[i];
					fd.append(key, value);
				}
                const alternativeUnitIds = this.product.alternative_unit_ids.map(unitId => {
                    return {
                        unit_id: unitId
                    }
                })
                fd.append('specifications', JSON.stringify(this.product.specifications))
                fd.append('alternative_unit_ids', JSON.stringify(alternativeUnitIds))
                // have to refactor
                // return
                let res = await this.storeProduct(fd)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.$refs.product.toggleModal()
                    this.resetForm()
                    this.getProductList()
                    this.showSuccess(res.message)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.data?.message)
                }
            } finally {
                 this.$refs.product.loading = false
            }
        },

        async handleGroupUpdate() {
            this.renderTree = false;
            // return;
            try {
                this.$refs.groupedit.loading = true
                this.groupAddData.company_id = this.companyId
                // have to refactor
                let res = await this.updateGroup(this.groupEditData)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.$refs.groupedit.toggleModal()
                    this.getGroup()
                    this.getProductList(this.accountType)
                    this.showSuccess(res.message)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.$refs.groupedit.loading = false
            }
        },
        async handleGroupSubmit() {
            this.renderTree = false;
            try {
                this.$refs.group.loading = true
                this.groupAddData.company_id = this.companyId
                // have to refactor
                let res = await this.storeGroup(this.groupAddData)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.$refs.group.toggleModal()
                    this.getGroup()
                    this.getProductList(this.accountType)
                    this.fet
                    this.groupAddData = {
                        name: '',
                        product_type: null,
                        status:'active',
                        company_id: '',
                        head_group_id: null
                    }
                    this.showSuccess(res.message)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                 this.$refs.group.loading = false
            }
        },

        setDefaultHead(data) {
            let product = this.product
            if(!data.length) return
            //set first account payable as default
            let firstPurchaseAccount = data.find(i => i.account_slug == 'purchase_accounts')
            if(firstPurchaseAccount) product.purchase_account_head_id = firstPurchaseAccount.id
            //set first account receivable as default
            let firstReceiveAccount = data.find(i => i.account_slug == 'sales_accounts')
            if(firstReceiveAccount) product.sales_account_head_id = firstReceiveAccount.id
            //set first cost of sales head as default
            let firstCostAccount = data.find(i => i.account_slug == 'cost_of_sales')
            if(firstCostAccount) product.cost_of_sales_account_head_id = firstCostAccount.id
            //set first inventory head as default
            let firstInventoryAccount = data.find(i => i.account_slug == 'inventory_assets')
            if(firstInventoryAccount) product.inventory_account_head_id = firstInventoryAccount.id

        },

        async getInitialData() {
            const query = `?company_id=${this.companyId}&product_type=${this.$route.params.accountType}`
            this.loading = true
            let p1= this.fetchUnit(this.companyId)
            let p2= this.fetchAccountHeads(this.companyId)
            let p3= this.fetchGroup(this.companyId, this.productType)
            let p4= this.fetchClue(this.companyId)
            let p5= this.fetchSpecification(this.companyId)
            let p6= this.fetchGroupParent(query)
            Promise.all([
                p1.then(res => {
                    this.units = res.data
                }),
                p2.then(res => {
                   if(res.data) {
                       this.accountHeads = res.data
                        this.setDefaultHead(res.data)
                   }
                }),
                p3.then(res => {
                   if(res.data) this.productGroup = res.data
                }),
                p4.then(res => {
                    this.clue = res.data
                }),
                p5.then(res => {
                    this.specifications = res.data
                }),
                p6.then(res => {
                    if(res.data) {
                        this.productSet.group = res.data
                        this.productSet.endpoint = {}
                    }
                }),
            ])
            .then(res => {
                this.loading = false
            })
            .catch(error => {
                this.loading = false
                // console.error(error.message)
            });
        },

        setBrandValue(name, id) {
            this.product.specifications[name] = id
        },
        setBrandValuetoEditform(name, id) {
            this.productSingle.specifications[name] = id
        },
        
        removeBrandValue(name) {
            delete this.product.specifications[name]
        },

        resetForm() {
            let product = this.product
            product.name = ''
            product.unit_id = null
            product.purchase_price = ''
            product.sales_price = ''
            product.cost_of_sales = ''
            product.sku = ''
            product.short_description = ''
            product.full_description = ''
            product.vat_rate = ''
            product.alternative_unit_ids = []
            product.inventory_method = 'fifo'
        }
    },

    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');

        const {
            fetchHome,
            chartCreateLoading,
            updateAccountGroup,
            chartLoading,

            fetchClue,
            fetchAccountHeads,
            fetchUnit,
            fetchGroup,
            fetchSpecification,
            storeProduct,
            fetchProductList,
            storeGroup,
            updateGroup,
            updateProduct,
            getProduct,
            storeSpecification,
            fetchGroupParent
        } = handleInventory()

        return {
            chartCreateLoading,
            updateAccountGroup,
            chartLoading,
            fetchHome,
            showError,
            showSuccess,

            fetchClue,
            fetchAccountHeads,
            fetchUnit,
            fetchGroup,
            fetchSpecification,
            storeProduct,
            fetchProductList,
            storeGroup,
            updateGroup,
            updateProduct,
            getProduct,
            storeSpecification,
            fetchGroupParent
        }
    },

    mounted () {
        const groupCreateModal = this.$refs.group
        const groupEditModal = this.$refs.groupedit
        const productAddModal = this.$refs.product
        const productEditModal = this.$refs.productedit

        this.getInitialData()
        this.getHomeContent() //status, voucher, account_type

        this.emitter.on('onClickAddGroup', (evt) => {
            this.groupAddData.product_type = this.productType
            this.groupAddData.head_group_id = evt.id
            groupCreateModal.toggleModal();
        })

        this.emitter.on('onClickAddProduct', (evt) => {
            this.product.product_head_id = evt.id
            productAddModal.toggleModal();
        })

        this.emitter.on('onClickProduct', (evt) => {
            this.fetchSingleProduct(evt)
            productEditModal.toggleModal();
        })

        this.emitter.on('onGroupUpdate', (evt) => {
            let data = {
                id: evt.data.id,
                name: evt.data.name,
                product_type: evt.data.product_type,
                status:'active',
                company_id: this.companyId,
                head_group_id: evt.data.product_id,
                _method:'PATCH'
            }
            this.groupEditData = data
            this.showParent=true
            groupEditModal.toggleModal();
        })
    }
}
</script>
