<template>
    <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{title}}</h1>
                            <button
                                @click="toggleModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">
                
                <div class="pb-2">
                    <!-- form -->
                    <vForm @submit="submit" :validation-schema="schema">
                        <div class="row gy-2 px-2">
                            <div class="col-12">
                                <label class="form-label" for="chartName">Name</label>
                                <div class="input-group">
                                    <vField
                                        type="text"
                                        name="name"
                                        v-model="formData.name"
                                        class="form-control add-credit-card-mask" 
                                        placeholder="Name"
                                    />
                                    <ErrorMessage name="name" class="invalid-feedback d-block qcont"/>
                                </div>
                            </div>
                           <div class="col-md-12">
                             <vField  v-model="formData.clue" name="clue" class="d-none"/>
                                <label class="form-label" for="clue">Specification type</label>
                                <v-select
                                    v-model="formData.clue"
                                    label="name"
                                    :reduce="name => name.id"
                                    :options="clueArr"
                                />
                                <ErrorMessage name="clue" class="invalid-feedback d-block qcont"/>
                            </div>
                             <div class="col-12">
                                <label class="form-label" for="description">Description</label>
                                <div class="input-group">
                                    <vField
                                        as="textarea"
                                        type="text"
                                        name="description"
                                        v-model="formData.description"
                                        class="form-control add-credit-card-mask" 
                                        placeholder="Description"
                                    />
                                    <ErrorMessage name="description" class="invalid-feedback d-block qcont"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 text-center">
                            <hr class="mt-2">
                            <button :disabled="loading" type="submit" class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                Submit
                            </button>
                            <button @click.prevent="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                Cancel
                            </button>
                        </div>
                    </vForm>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import objToArray from '@/services/utils/object-to-array'

export default {
    name: 'SpecificationModal',

    data: () => ({
        isOpen: false,
        loading: false,
        schema: {
            name: 'required|min:3|max:150',
            clue: 'required'
        }
    }),

    computed: {
        clueArr() {
            return objToArray(this.clue)
        }
    },


    methods: {
        submit () {
            this.$emit("onSubmitSpecification")
        },
        toggleModal () {
            if(this.isOpen) this.$emit('onClose')
            this.isOpen = !this.isOpen
        },
        onUploadOwnerPhoto(event) {
            this.formData.owner_photo = event.target.files[0]
        },
        onUploadBusinessPhoto(event) {
            this.formData.business_photo = event.target.files[0]
        }
    },

    props: {
        formData: Object,
        clue: Object,
        title: {
            default: 'Add Specification'
        }
    }
}
</script>